export const strongMultiPages = [
  { href: "/strong-multi-page", text: "Home" },
  { href: "/strong-about", text: "About", className: "active" },
  { href: "/strong-services", text: "Services" },
  { href: "/strong-portfolio", text: "Portfolio" },
  { href: "/strong-blog", text: "Blog" },
  { href: "/strong-contact", text: "Contact" },
];
export const strongMultiPagesDark = [
  { href: "/strong-multi-page-dark", text: "Home" },
  { href: "/strong-about-dark", text: "About", className: "active" },
  { href: "/strong-services-dark", text: "Services" },
  { href: "/strong-portfolio-dark", text: "Portfolio" },
  { href: "/strong-blog-dark", text: "Blog" },
  { href: "/strong-contact-dark", text: "Contact" },
];
export const strongSinglePages = [
  { href: "#home", text: "Home", className: "active" },
  { href: "#about", text: "About" },
  { href: "#services", text: "Services" },
  { href: "#portfolio", text: "Portfolio" },
  { href: "#blog", text: "Blog" },
  { href: "#contact", text: "Contact" },
];

export const slickMultipages = [
  { href: "/slick-multi-page", text: "Home" },
  { href: "/slick-about", text: "About", class: "active" },
  { href: "/slick-services", text: "Services" },
  { href: "/slick-portfolio", text: "Portfolio" },
  { href: "/slick-blog", text: "Blog" },
  { href: "/slick-contact", text: "Contact" },
];
export const slickMultipagesDark = [
  { href: "/slick-multi-page-dark", text: "Home" },
  { href: "/slick-about-dark", text: "About", class: "active" },
  { href: "/slick-services-dark", text: "Services" },
  { href: "/slick-portfolio-dark", text: "Portfolio" },
  { href: "/slick-blog-dark", text: "Blog" },
  { href: "/slick-contact-dark", text: "Contact" },
];
export const slickOnepage = [
  { href: "#home", text: "Home", className: "active" },
  { href: "#about", text: "About" },
  { href: "#services", text: "Services" },
  { href: "#portfolio", text: "Portfolio" },
  { href: "#blog", text: "Blog" },
  { href: "#contact", text: "Contact" },
];

export const modernMultipage = [
  { id: 1, href: "/modern-multi-page", text: "Home" },
  { id: 2, href: "/modern-about", text: "About", className: "active" },
  { id: 3, href: "/modern-services", text: "Services" },
  { id: 4, href: "/modern-portfolio", text: "Portfolio" },
  { id: 5, href: "/modern-blog", text: "Blog" },
  { id: 6, href: "/modern-contact", text: "Contact" },
];
export const modernMultipageDark = [
  { id: 1, href: "/modern-multi-page-dark", text: "Home" },
  { id: 2, href: "/modern-about-dark", text: "About", className: "active" },
  { id: 3, href: "/modern-services-dark", text: "Services" },
  { id: 4, href: "/modern-portfolio-dark", text: "Portfolio" },
  { id: 5, href: "/modern-blog-dark", text: "Blog" },
  { id: 6, href: "/modern-contact-dark", text: "Contact" },
];

export const modernOnepage = [
  { href: "#home", text: "Home", className: "active", dataBtnAnimate: "y" },
  { href: "#about", text: "About", dataBtnAnimate: "y" },
  { href: "#services", text: "Services", dataBtnAnimate: "y" },
  { href: "#portfolio", text: "Portfolio", dataBtnAnimate: "y" },
  { href: "#blog", text: "Blog", dataBtnAnimate: "y" },
  { href: "#contact", text: "Contact", dataBtnAnimate: "y" },
];

export const gradientMultipage = [
  { href: "/gradient-multi-page", text: "Home", active: true },
  { href: "/gradient-about", text: "About", active: false },
  { href: "/gradient-services", text: "Services", active: false },
  { href: "/gradient-portfolio", text: "Portfolio", active: false },
  { href: "/gradient-blog", text: "Blog", active: false },
  { href: "/gradient-contact", text: "Contact", active: false },
];
export const gradientMultipageDark = [
  { href: "/gradient-multi-page-dark", text: "Home", active: true },
  { href: "/gradient-about-dark", text: "About", active: false },
  { href: "/gradient-services-dark", text: "Services", active: false },
  { href: "/gradient-portfolio-dark", text: "Portfolio", active: false },
  { href: "/gradient-blog-dark", text: "Blog", active: false },
  { href: "/gradient-contact-dark", text: "Contact", active: false },
];

export const gradientOnePage = [
  { href: "#home", text: "Home", className: "active", dataBtnAnimate: "y" },
  { href: "#about", text: "About", dataBtnAnimate: "y" },
  { href: "#services", text: "Services", dataBtnAnimate: "y" },
  { href: "#portfolio", text: "Portfolio", dataBtnAnimate: "y" },
  { href: "#blog", text: "Blog", dataBtnAnimate: "y" },
  { href: "#contact", text: "Contact", dataBtnAnimate: "y" },
];

export const fancyMultipage = [
  { href: "/fancy-multi-page", text: "Home", class: "active" },
  { href: "/fancy-about", text: "About" },
  { href: "/fancy-services", text: "Services" },
  { href: "/fancy-portfolio", text: "Portfolio" },
  { href: "/fancy-blog", text: "Blog" },
  { href: "/fancy-contact", text: "Contact" },
];

export const fancyMultipageDark = [
  { href: "/fancy-multi-page-dark", text: "Home", class: "active" },
  { href: "/fancy-about-dark", text: "About" },
  { href: "/fancy-services-dark", text: "Services" },
  { href: "/fancy-portfolio-dark", text: "Portfolio" },
  { href: "/fancy-blog-dark", text: "Blog" },
  { href: "/fancy-contact-dark", text: "Contact" },
];

export const fancyOnepage = [
  { href: "#home", text: "Home", className: "active" },
  { href: "#about", text: "About" },
  { href: "#services", text: "Services" },
  { href: "#portfolio", text: "Portfolio" },
  { href: "#blog", text: "Blog" },
  { href: "#pricing", text: "Pricing" },
  { href: "#contact", text: "Contact" },
];

export const elegantMultipage = [
  { href: "/elegant-multi-page", text: "Home" },
  { href: "/elegant-about", text: "About" },
  { href: "/elegant-services", text: "Services" },
  { href: "/elegant-portfolio", text: "Portfolio" },
  { href: "/elegant-blog", text: "Blog" },
  { href: "/elegant-contact", text: "Contact" },
];
export const elegantMultipageDark = [
  { href: "/elegant-multi-page-dark", text: "Home" },
  { href: "/elegant-about-dark", text: "About" },
  { href: "/elegant-services-dark", text: "Services" },
  { href: "/elegant-portfolio-dark", text: "Portfolio" },
  { href: "/elegant-blog-dark", text: "Blog" },
  { href: "/elegant-contact-dark", text: "Contact" },
];
export const elegantOnepage = [
  { href: "#home", text: "Home", className: "active" },
  { href: "#about", text: "About" },
  { href: "#services", text: "Services" },
  { href: "#portfolio", text: "Portfolio" },
  { href: "#blog", text: "Blog" },
  { href: "#contact", text: "Contact" },
];

export const corporateMultipage = [
  { href: "/corporate-multi-page", text: "Home" },
  { href: "/corporate-about", text: "About" },
  { href: "/corporate-services", text: "Services" },
  { href: "/corporate-portfolio", text: "Portfolio" },
  { href: "/corporate-blog", text: "Blog" },
  { href: "/corporate-contact", text: "Contact" },
];

export const corporateMultipageDark = [
  { href: "/corporate-multi-page-dark", text: "Home" },
  { href: "/corporate-about-dark", text: "About" },
  { href: "/corporate-services-dark", text: "Services" },
  { href: "/corporate-portfolio-dark", text: "Portfolio" },
  { href: "/corporate-blog-dark", text: "Blog" },
  { href: "/corporate-contact-dark", text: "Contact" },
];

export const corporateOnepage = [
  { href: "#home", text: "Home", className: "active" },
  { href: "#about", text: "About" },
  { href: "#services", text: "Services" },
  { href: "#portfolio", text: "Portfolio" },
  { href: "#blog", text: "Blog" },
  { href: "#contact", text: "Contact" },
];

export const brutalistMultipage = [
  { href: "/brutalist-multi-page", text: "Home", active: true },
  { href: "/brutalist-about", text: "About", active: false },
  { href: "/brutalist-services", text: "Services", active: false },
  { href: "/brutalist-portfolio", text: "Portfolio", active: false },
  { href: "/brutalist-blog", text: "Blog", active: false },
];
export const brutalistMultipageDark = [
  { href: "/brutalist-multi-page-dark", text: "Home", active: true },
  { href: "/brutalist-about-dark", text: "About", active: false },
  { href: "/brutalist-services-dark", text: "Services", active: false },
  { href: "/brutalist-portfolio-dark", text: "Portfolio", active: false },
  { href: "/brutalist-blog-dark", text: "Blog", active: false },
];
export const brutalistOnepage = [
  { href: "#home", text: "Home", className: "active", dataBtnAnimate: "y" },
  { href: "#about", text: "About", dataBtnAnimate: "y" },
  { href: "#services", text: "Services", dataBtnAnimate: "y" },
  { href: "#portfolio", text: "Portfolio", dataBtnAnimate: "y" },
  { href: "#blog", text: "Blog", dataBtnAnimate: "y" },
];
export const boldMultipage = [
  { href: "/bold-multi-page", text: "Home", active: false },
  { href: "/bold-about", text: "About", active: true },
  { href: "/bold-services", text: "Services", active: false },
  { href: "/bold-portfolio", text: "Portfolio", active: false },
  { href: "/bold-blog", text: "Blog", active: false },
  { href: "/bold-contact", text: "Contact", active: false },
];
export const boldMultipageDark = [
  { href: "/bold-multi-page-dark", text: "Home", active: false },
  { href: "/bold-about-dark", text: "About", active: true },
  { href: "/bold-services-dark", text: "Services", active: false },
  { href: "/bold-portfolio-dark", text: "Portfolio", active: false },
  { href: "/bold-blog-dark", text: "Blog", active: false },
  { href: "/bold-contact-dark", text: "Contact", active: false },
];

export const boldOnepage = [
  { href: "#home", text: "Home", className: "active" },
  { href: "#about", text: "About" },
  { href: "#services", text: "Services" },
  { href: "#portfolio", text: "Portfolio" },
  { href: "#blog", text: "Blog" },
  { href: "#contact", text: "Contact" },
];

export const mainDemoOnepage = [
  { href: "#home", text: "Home", className: "active" },
  { href: "#about", text: "About" },
  { href: "#services", text: "Services" },
  { href: "#portfolio", text: "Portfolio" },
  { href: "#blog", text: "Blog" },
  { href: "#contact", text: "Contact" },
];

export const hometwopage = [
  { href: "home", text: "Home", className: "active" },
  // { href: "about", text: "About" },
  // { href: "services", text: "Services" },

  { href: "about", text: "About Us" },
  { href: "pricing", text: "Pricing" },
  { href: "contactus", text: "Contact Us" },
];

// HRMS Menu
export const menuItems = [

  {
    title: "Home",
    subMenu: [
      {
        title: "Multi Page",
        links: [
          { href: "/main-multi-page", text: "Main Demo" },
          { href: "/main-multi-page-typed-text", text: "Typed Text" },
          { href: "/main-multi-page-image-parallax", text: "Image Parallax" },
          {
            href: "/main-multi-page-slider-fullscreen",
            text: "Slider Fullscreen",
          },
          {
            href: "/main-multi-page-slider-background",
            text: "Slider Background",
          },
          { href: "/main-multi-page-bg-video", text: "BG Video HTML5" },
          {
            href: "/main-multi-page-bg-video-youtube",
            text: "BG Video Youtube",
          },
          { href: "/main-multi-page-dark", text: "Dark Version" },
        ],
      },
      {
        title: "One Page",
        links: [
          { href: "/main-one-page", text: "Main Demo" },
          { href: "/main-one-page-typed-text", text: "Typed Text" },
          { href: "/main-one-page-image-parallax", text: "Image Parallax" },
          {
            href: "/main-one-page-slider-fullscreen",
            text: "Slider Fullscreen",
          },
          {
            href: "/main-one-page-slider-background",
            text: "Slider Background",
          },
          { href: "/main-one-page-bg-video", text: "BG Video HTML5" },
          { href: "/main-one-page-bg-video-youtube", text: "BG Video Youtube" },
          { href: "/main-one-page-dark", text: "Dark Version" },
        ],
      },
    ],
  },
  {
    title: "Pages",
    subMenu: [
      {
        title: "Group 1",
        links: [
          { href: "/main-pages-about-1", text: "About Us 1" },
          { href: "/main-pages-about-2", text: "About Us 2" },
          { href: "/main-pages-about-3", text: "About Us 3" },
          { href: "/main-pages-services-1", text: "Services 1" },
          { href: "/main-pages-services-2", text: "Services 2" },
          { href: "/main-pages-services-3", text: "Services 3" },
          { href: "/main-pages-clients", text: "Clients" },
        ],
      },
      {
        title: "Group 2",
        links: [
          { href: "/main-pages-team", text: "Team" },
          { href: "/main-pages-faq-1", text: "FAQ 1" },
          { href: "/main-pages-faq-2", text: "FAQ 2" },
          { href: "/main-pages-pricing-1", text: "Pricing 1" },
          { href: "/main-pages-pricing-2", text: "Pricing 2" },
          { href: "/main-pages-contact-1", text: "Contact 1" },
          { href: "/main-pages-contact-2", text: "Contact 2" },
        ],
      },
      {
        title: "Group 3",
        links: [
          { href: "/main-pages-gallery-1col", text: "Gallery Col 1" },
          { href: "/main-pages-gallery-2col", text: "Gallery Col 2" },
          { href: "/main-pages-gallery-3col", text: "Gallery Col 3" },
          { href: "/main-pages-gallery-4col", text: "Gallery Col 4" },
          { href: "/main-pages-account", text: "Login / Register" },
          {
            href: "/main-pages-under-construction",
            text: "Under Construction",
          },
          { href: "/main-pages-404", text: "Error 404" },
        ],
      },
    ],
  },
  {
    title: "Elements",
    subMenu: [
      {
        links: [
          {
            href: "/main-elements-accordions",
            icon: "mi-circle-add",
            text: "Accordions",
          },
          {
            href: "/main-elements-alert-messages",
            icon: "mi-circle-information",
            text: "Alert Messages",
          },
          {
            href: "/main-elements-animation",
            icon: "mi-play",
            text: "Animation",
          },
          {
            href: "/main-elements-bars",
            icon: "mi-bar-chart",
            text: "Bars (Progress)",
          },
          { href: "/main-elements-buttons", icon: "mi-link", text: "Buttons" },
          {
            href: "/main-elements-cookie-banner",
            icon: "mi-flag",
            text: "Cookie Banner",
          },
          {
            href: "/main-elements-features",
            icon: "mi-grid",
            text: "Feature Boxes",
          },
          {
            href: "/main-elements-forms",
            icon: "mi-three-rows",
            text: "Form Fields",
          },
          { href: "/main-elements-grid", icon: "mi-layout", text: "Grid" },
          {
            href: "/main-elements-icons-brands",
            icon: "mi-circle",
            text: "Icons Brands",
          },
          {
            href: "/main-elements-icons-iconmonstr",
            icon: "mi-favorite",
            text: "Icons Iconmonstr",
          },
          {
            href: "/main-elements-icons-Ionicons",
            icon: "mi-heart",
            text: "Icons Ionicons",
          },
          {
            href: "/main-elements-icons-monoicons",
            icon: "mi-sun",
            text: "Icons Monoicons",
          },
        ],
      },
      {
        links: [
          {
            href: "/main-elements-images-lazyload",
            icon: "mi-image",
            text: "Images Lazyload",
          },
          {
            href: "/main-elements-lightbox",
            icon: "mi-window",
            text: "Lightbox",
          },
          {
            href: "/main-elements-sliders",
            icon: "mi-circle-arrow-right",
            text: "Sliders",
          },
          {
            href: "/main-elements-sliders-lazyload",
            icon: "mi-circle-arrow-right",
            text: "Sliders Lazyload",
          },
          { href: "/main-elements-tabs", icon: "mi-two-columns", text: "Tabs" },
          {
            href: "/main-elements-tooltips",
            icon: "mi-message",
            text: "Tooltips",
          },
          {
            href: "/main-elements-typography",
            icon: "mi-text",
            text: "Typography",
          },
          {
            href: "/main-elements-video-sound",
            icon: "mi-webcam",
            text: "Video / Sound",
          },
          {
            href: "/main-elements-header-dark-light",
            icon: "mi-book",
            text: "Header Dark",
          },
          {
            href: "/main-elements-header-dark-transparent",
            icon: "mi-book",
            text: "Header Dark Transp",
          },
          {
            href: "/main-elements-header-white",
            icon: "mi-book",
            text: "Header White",
          },
          {
            href: "/main-elements-header-white-transparent",
            icon: "mi-book",
            text: "Header White Transp",
          },
          {
            href: "/main-elements-header-white-after-scroll",
            icon: "mi-book",
            text: "Header Scroll-White",
          },
        ],
      },
    ],
  },
  {
    title: "Portfolio",
    subItems: [
      {
        title: "Boxed",
        links: [
          { href: "/main-portfolio-boxed-2col", label: "2 Columns" },
          { href: "/main-portfolio-boxed-3col", label: "3 Columns" },
          { href: "/main-portfolio-boxed-4col", label: "4 Columns" },
        ],
      },
      {
        title: "Wide",
        links: [
          { href: "/main-portfolio-wide-2col", label: "2 Columns" },
          { href: "/main-portfolio-wide-3col", label: "3 Columns" },
          { href: "/main-portfolio-wide-4col", label: "4 Columns" },
        ],
      },
      {
        title: "Masonry",
        links: [
          { href: "/main-portfolio-masonry-2col", label: "2 Columns" },
          { href: "/main-portfolio-masonry-3col", label: "3 Columns" },
          { href: "/main-portfolio-masonry-4col", label: "4 Columns" },
        ],
      },
      {
        title: "Single",
        links: [
          { href: "/main-portfolio-single-1/1", label: "Single 1" },
          { href: "/main-portfolio-single-2/1", label: "Single 2" },
          { href: "/main-portfolio-single-3/1", label: "Single 3" },
        ],
      },
    ],
  },
  {
    title: "Blog",
    subItems: [
      {
        title: "Classic",
        links: [
          { href: "/main-blog-classic-sidebar-left", label: "Sidebar Left" },
          { href: "/main-blog-classic-sidebar-right", label: "Sidebar Right" },
          { href: "/main-blog-classic-fullwidth", label: "Fullwidth" },
        ],
      },
      {
        title: "Columns",
        links: [
          { href: "/main-blog-columns-2col", label: "2 Columns" },
          { href: "/main-blog-columns-3col", label: "3 Columns" },
        ],
      },
      {
        title: "Single",
        links: [
          { href: "/main-blog-single-sidebar-left/1", label: "Sidebar Left" },
          { href: "/main-blog-single-sidebar-right/1", label: "Sidebar Right" },
          { href: "/main-blog-single-fullwidth/1", label: "Fullwidth" },
        ],
      },
    ],
  },
  {
    title: "My Page",
    subMenu: [
      {
        title: "All Pages",
        links: [
          { href: "/about", text: "About Us", icon: "mi-circle-information" },
          { href: "/pricing", text: "Pricing" },
          { href: "/careers", text: "Career" },
          { href: "/testimonials", text: "Testimonial" },
          { href: "/contactus/", text: "Contact" },
          { href: "/customers", text: "Customer" },
          { href: "/blog", text: "Blogs" },
          { href: "/blog/blogread", text: "Blog Read" },
          { href: "/glossary", text: "Glossary" },
          { href: "/glossary/glossaryread", text: "Glossary Read" },
          { href: "/book-demo", text: "Book Demo" },
          { href: "/product-tours", text: "Product Tours" },
        ],
      },
      {
        title: "HRMS",
        links: [
          { href: "/hrms", text: "HRMS" },
          { href: "/hrms-software/leave-management-system", text: "Leave Management System" },
          { href: "/hrms-software/leave-management-system/leave-policy-allocation", text: "Leave Policy Allocation" },
          { href: "/hrms-software/leave-management-system/leave-balance", text: "Leave Balance" },
          { href: "/hrms-software/leave-management-system/leave-accrual-details", text: "Leave Accrual Details" },
          { href: "/hrms-software/leave-management-system/leave-approval/", text: "Easy Approval/Decline" },
          { href: "/hrms-software/leave-management-system/leave-history/", text: "Leave History" },
          { href: "/hrms-software/leave-management-system/leave-types/", text: "Leave Types" },
        ],
      },
      {
        title: "Solutions",
        links: [
          { href: "/solutions/it-solutions/", text: "IT Solutions" },
          { href: "/solutions/marketing-automation/", text: "Marketing Automation" },
          { href: "/solutions/customer-service-solutions/", text: "Customer Service Solutions" },
          { href: "/solutions/customer-relationship-management/", text: "Customer Relationship Management" },
          { href: "/solutions/enterprise-service-management/", text: "Enterprise Service Management" },
          { href: "/solutions/sales-automation/", text: "Sales Automation" },
          { href: "solutions/ca-firm/", text: "CA Firm" },
          { href: "/solutions/cs-firm/", text: "CS Firm" },
          { href: "/solutions/cpa-firm/", text: "CPA Firm" },
          { href: "/solutions/law-firm/", text: "LAW Firm" },
          { href: "/solutions/bpo-kpo/", text: "BPO/KPO" },
          { href: "/solutions/real-estate/", text: "Real Estate" },
        ],
      },
      {
        title: "All Pages",
        links: [
          { href: "/about", text: "About Us", icon: "mi-circle-information" },
          { href: "/pricing", text: "Pricing" },
          { href: "/careers", text: "Career" },
          { href: "/testimonials", text: "Testimonial" },
          { href: "/contactus/", text: "Contact" },
          { href: "/customers", text: "Customer" },
          { href: "/blog/", text: "Blogs" },
          { href: "/blog/blogread", text: "Blog Read" },
          { href: "/glossary", text: "Glossary" },
          { href: "/glossary/glossaryread", text: "Glossary Read" },
          { href: "/book-demo", text: "Book Demo" },
          { href: "/product-tours", text: "Product Tours" },
        ],
      },
    ],
  },
];

export const menuItemsDark = [
  {
    title: "Home",
    subMenu: [
      {
        title: "Multi Page",
        links: [
          { href: "/main-multi-page-dark", text: "Main Demo" },
          { href: "/main-multi-page-typed-text-dark", text: "Typed Text" },
          {
            href: "/main-multi-page-image-parallax-dark",
            text: "Image Parallax",
          },
          {
            href: "/main-multi-page-slider-fullscreen-dark",
            text: "Slider Fullscreen",
          },
          {
            href: "/main-multi-page-slider-background-dark",
            text: "Slider Background",
          },
          { href: "/main-multi-page-bg-video-dark", text: "BG Video HTML5" },
          {
            href: "/main-multi-page-bg-video-youtube-dark",
            text: "BG Video Youtube",
          },
          { href: "/main-multi-page-dark-dark", text: "Dark Version" },
        ],
      },
      {
        title: "One Page",
        links: [
          { href: "/main-one-page-dark", text: "Main Demo" },
          { href: "/main-one-page-typed-text-dark", text: "Typed Text" },
          {
            href: "/main-one-page-image-parallax-dark",
            text: "Image Parallax",
          },
          {
            href: "/main-one-page-slider-fullscreen-dark",
            text: "Slider Fullscreen",
          },
          {
            href: "/main-one-page-slider-background-dark",
            text: "Slider Background",
          },
          { href: "/main-one-page-bg-video-dark", text: "BG Video HTML5" },
          {
            href: "/main-one-page-bg-video-youtube-dark",
            text: "BG Video Youtube",
          },
          { href: "/main-one-page-dark", text: "Dark Version" },
        ],
      },
    ],
  },
  {
    title: "Pages",
    subMenu: [
      {
        title: "Group 1",
        links: [
          { href: "/main-pages-about-1-dark", text: "About Us 1" },
          { href: "/main-pages-about-2-dark", text: "About Us 2" },
          { href: "/main-pages-about-3-dark", text: "About Us 3" },
          { href: "/main-pages-services-1-dark", text: "Services 1" },
          { href: "/main-pages-services-2-dark", text: "Services 2" },
          { href: "/main-pages-services-3-dark", text: "Services 3" },
          { href: "/main-pages-clients-dark", text: "Clients" },
        ],
      },
      {
        title: "Group 2",
        links: [
          { href: "/main-pages-team-dark", text: "Team" },
          { href: "/main-pages-faq-1-dark", text: "FAQ 1" },
          { href: "/main-pages-faq-2-dark", text: "FAQ 2" },
          { href: "/main-pages-pricing-1-dark", text: "Pricing 1" },
          { href: "/main-pages-pricing-2-dark", text: "Pricing 2" },
          { href: "/main-pages-contact-1-dark", text: "Contact 1" },
          { href: "/main-pages-contact-2-dark", text: "Contact 2" },
        ],
      },
      {
        title: "Group 3",
        links: [
          { href: "/main-pages-gallery-1col-dark", text: "Gallery Col 1" },
          { href: "/main-pages-gallery-2col-dark", text: "Gallery Col 2" },
          { href: "/main-pages-gallery-3col-dark", text: "Gallery Col 3" },
          { href: "/main-pages-gallery-4col-dark", text: "Gallery Col 4" },
          { href: "/main-pages-account-dark", text: "Login / Register" },
          {
            href: "/main-pages-under-construction",
            text: "Under Construction",
          },
          { href: "/main-pages-404", text: "Error 404" },
        ],
      },
    ],
  },
  {
    title: "Elements",
    subMenu: [
      {
        links: [
          {
            href: "/main-elements-accordions-dark",
            icon: "mi-circle-add",
            text: "Accordions",
          },
          {
            href: "/main-elements-alert-messages-dark",
            icon: "mi-circle-information",
            text: "Alert Messages",
          },
          {
            href: "/main-elements-animation-dark",
            icon: "mi-play",
            text: "Animation",
          },
          {
            href: "/main-elements-bars-dark",
            icon: "mi-bar-chart",
            text: "Bars (Progress)",
          },
          {
            href: "/main-elements-buttons-dark",
            icon: "mi-link",
            text: "Buttons",
          },
          {
            href: "/main-elements-cookie-banner-dark",
            icon: "mi-flag",
            text: "Cookie Banner",
          },
          {
            href: "/main-elements-features-dark",
            icon: "mi-grid",
            text: "Feature Boxes",
          },
          {
            href: "/main-elements-forms-dark",
            icon: "mi-three-rows",
            text: "Form Fields",
          },
          {
            href: "/main-elements-grid-dark",
            icon: "mi-layout",
            text: "Grid",
          },
          {
            href: "/main-elements-icons-brands-dark",
            icon: "mi-circle",
            text: "Icons Brands",
          },
          {
            href: "/main-elements-icons-iconmonstr-dark",
            icon: "mi-favorite",
            text: "Icons Iconmonstr",
          },
          {
            href: "/main-elements-icons-Ionicons-dark",
            icon: "mi-heart",
            text: "Icons Ionicons",
          },
          {
            href: "/main-elements-icons-monoicons-dark",
            icon: "mi-sun",
            text: "Icons Monoicons",
          },
        ],
      },
      {
        links: [
          {
            href: "/main-elements-images-lazyload-dark",
            icon: "mi-image",
            text: "Images Lazyload",
          },
          {
            href: "/main-elements-lightbox-dark",
            icon: "mi-window",
            text: "Lightbox",
          },
          {
            href: "/main-elements-sliders-dark",
            icon: "mi-circle-arrow-right",
            text: "Sliders",
          },
          {
            href: "/main-elements-sliders-lazyload-dark",
            icon: "mi-circle-arrow-right",
            text: "Sliders Lazyload",
          },
          {
            href: "/main-elements-tabs-dark",
            icon: "mi-two-columns",
            text: "Tabs",
          },
          {
            href: "/main-elements-tooltips-dark",
            icon: "mi-message",
            text: "Tooltips",
          },
          {
            href: "/main-elements-typography-dark",
            icon: "mi-text",
            text: "Typography",
          },
          {
            href: "/main-elements-video-sound-dark",
            icon: "mi-webcam",
            text: "Video / Sound",
          },
          {
            href: "/main-elements-header-dark",
            icon: "mi-book",
            text: "Header Dark",
          },
          {
            href: "/main-elements-header-dark-transparent-dark",
            icon: "mi-book",
            text: "Header Dark Transp",
          },

          {
            href: "/main-elements-header-white-after-scroll-dark",
            icon: "mi-book",
            text: "Header Scroll-White",
          },
        ],
      },
    ],
  },
  {
    title: "Portfolio",
    subItems: [
      {
        title: "Boxed",
        links: [
          { href: "/main-portfolio-boxed-2col-dark", label: "2 Columns" },
          { href: "/main-portfolio-boxed-3col-dark", label: "3 Columns" },
          { href: "/main-portfolio-boxed-4col-dark", label: "4 Columns" },
        ],
      },
      {
        title: "Wide",
        links: [
          { href: "/main-portfolio-wide-2col-dark", label: "2 Columns" },
          { href: "/main-portfolio-wide-3col-dark", label: "3 Columns" },
          { href: "/main-portfolio-wide-4col-dark", label: "4 Columns" },
        ],
      },
      {
        title: "Masonry",
        links: [
          { href: "/main-portfolio-masonry-2col-dark", label: "2 Columns" },
          { href: "/main-portfolio-masonry-3col-dark", label: "3 Columns" },
          { href: "/main-portfolio-masonry-4col-dark", label: "4 Columns" },
        ],
      },
      {
        title: "Single",
        links: [
          { href: "/main-portfolio-single-1-dark/1", label: "Single 1" },
          { href: "/main-portfolio-single-2-dark/1", label: "Single 2" },
          { href: "/main-portfolio-single-3-dark/1", label: "Single 3" },
        ],
      },
    ],
  },
  {
    title: "Blog",
    subItems: [
      {
        title: "Classic",
        links: [
          {
            href: "/main-blog-classic-sidebar-left-dark",
            label: "Sidebar Left",
          },
          {
            href: "/main-blog-classic-sidebar-right-dark",
            label: "Sidebar Right",
          },
          { href: "/main-blog-classic-fullwidth-dark", label: "Fullwidth" },
        ],
      },
      {
        title: "Columns",
        links: [
          { href: "/main-blog-columns-2col-dark", label: "2 Columns" },
          { href: "/main-blog-columns-3col-dark", label: "3 Columns" },
        ],
      },
      {
        title: "Single",
        links: [
          {
            href: "/main-blog-single-sidebar-left-dark/1",
            label: "Sidebar Left",
          },
          {
            href: "/main-blog-single-sidebar-right-dark/1",
            label: "Sidebar Right",
          },
          { href: "/main-blog-single-fullwidth-dark/1", label: "Fullwidth" },
        ],
      },
    ],
  },
];

export const allHomes = [
  {
    title: "Main",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/main-one-page", text: "One Page" },
          { href: "/main-one-page-dark", text: "One Page Dark" },
          { href: "/main-multi-page", text: "Multi Page" },
          { href: "/main-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Typed Text",
        links: [
          { href: "/main-one-page-typed-text", text: "One Page" },
          { href: "/main-one-page-typed-text-dark", text: "One Page Dark" },
          { href: "/main-multi-page-typed-text", text: "Multi Page" },
          { href: "/main-multi-page-typed-text-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Image Parallax",
        links: [
          { href: "/main-one-page-image-parallax", text: "One Page" },
          { href: "/main-one-page-image-parallax-dark", text: "One Page Dark" },
          { href: "/main-multi-page-image-parallax", text: "Multi Page" },
          {
            href: "/main-multi-page-image-parallax-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "Slider Fullscreen",
        links: [
          { href: "/main-one-page-slider-fullscreen", text: "One Page" },
          {
            href: "/main-one-page-slider-fullscreen-dark",
            text: "One Page Dark",
          },
          { href: "/main-multi-page-slider-fullscreen", text: "Multi Page" },
          {
            href: "/main-multi-page-slider-fullscreen-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "Slider Background",
        links: [
          { href: "/main-one-page-slider-background", text: "One Page" },
          {
            href: "/main-one-page-slider-background-dark",
            text: "One Page Dark",
          },
          { href: "/main-multi-page-slider-background", text: "Multi Page" },
          {
            href: "/main-multi-page-slider-background-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "BG Video HTML5",
        links: [
          { href: "/main-one-page-bg-video", text: "One Page" },
          { href: "/main-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/main-multi-page-bg-video", text: "Multi Page" },
          { href: "/main-multi-page-bg-video-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "BG Video Youtube",
        links: [
          { href: "/main-one-page-bg-video-youtube", text: "One Page" },
          {
            href: "/main-one-page-bg-video-youtube-dark",
            text: "One Page Dark",
          },
          { href: "/main-multi-page-bg-video-youtube", text: "Multi Page" },
          {
            href: "/main-multi-page-bg-video-youtube-dark",
            text: "Multi Page Dark",
          },
        ],
      },
    ],
  },
  {
    title: "Bold",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/bold-one-page", text: "One Page" },
          { href: "/bold-one-page-dark", text: "One Page Dark" },
          { href: "/bold-multi-page", text: "Multi Page" },
          { href: "/bold-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Typed Text",
        links: [
          { href: "/bold-one-page-typed-text", text: "One Page" },
          { href: "/bold-one-page-typed-text-dark", text: "One Page Dark" },
          { href: "/bold-multi-page-typed-text", text: "Multi Page" },
          { href: "/bold-multi-page-typed-text-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "BG Video",
        links: [
          { href: "/bold-one-page-bg-video", text: "One Page" },
          { href: "/bold-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/bold-multi-page-bg-video", text: "Multi Page" },
          { href: "/bold-multi-page-bg-video-dark", text: "Multi Page Dark" },
        ],
      },
    ],
  },
  {
    title: "Brutalist",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/brutalist-one-page", text: "One Page" },
          { href: "/brutalist-one-page-dark", text: "One Page Dark" },
          { href: "/brutalist-multi-page", text: "Multi Page" },
          { href: "/brutalist-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "BG Video",
        links: [
          { href: "/brutalist-one-page-bg-video", text: "One Page" },
          { href: "/brutalist-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/brutalist-multi-page-bg-video", text: "Multi Page" },
          {
            href: "/brutalist-multi-page-bg-video-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "BG Video Youtube",
        links: [
          { href: "/brutalist-one-page-bg-video-youtube", text: "One Page" },
          {
            href: "/brutalist-one-page-bg-video-youtube-dark",
            text: "One Page Dark",
          },
          {
            href: "/brutalist-multi-page-bg-video-youtube",
            text: "Multi Page",
          },
          {
            href: "/brutalist-multi-page-bg-video-youtube-dark",
            text: "Multi Page Dark",
          },
        ],
      },
    ],
  },
  {
    title: "Corporate",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/corporate-one-page", text: "One Page" },
          { href: "/corporate-one-page-dark", text: "One Page Dark" },
          { href: "/corporate-multi-page", text: "Multi Page" },
          { href: "/corporate-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Image Parallax",
        links: [
          { href: "/corporate-one-page-image-parallax", text: "One Page" },
          {
            href: "/corporate-one-page-image-parallax-dark",
            text: "One Page Dark",
          },
          { href: "/corporate-multi-page-image-parallax", text: "Multi Page" },
          {
            href: "/corporate-multi-page-image-parallax-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "BG Video",
        links: [
          { href: "/corporate-one-page-bg-video", text: "One Page" },
          { href: "/corporate-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/corporate-multi-page-bg-video", text: "Multi Page" },
          {
            href: "/corporate-multi-page-bg-video-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "Split Screen",
        links: [
          { href: "/corporate-one-page-split", text: "One Page" },
          { href: "/corporate-one-page-split-dark", text: "One Page Dark" },
          { href: "/corporate-multi-page-split", text: "Multi Page" },
          { href: "/corporate-multi-page-split-dark", text: "Multi Page Dark" },
        ],
      },
    ],
  },
  {
    title: "Elegant",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/elegant-one-page", text: "One Page" },
          { href: "/elegant-one-page-dark", text: "One Page Dark" },
          { href: "/elegant-multi-page", text: "Multi Page" },
          { href: "/elegant-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "BG Video",
        links: [
          { href: "/elegant-one-page-bg-video", text: "One Page" },
          { href: "/elegant-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/elegant-multi-page-bg-video", text: "Multi Page" },
          {
            href: "/elegant-multi-page-bg-video-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "Split Screen",
        links: [
          { href: "/elegant-one-page-split", text: "One Page" },
          { href: "/elegant-one-page-split-dark", text: "One Page Dark" },
          { href: "/elegant-multi-page-split", text: "Multi Page" },
          { href: "/elegant-multi-page-split-dark", text: "Multi Page Dark" },
        ],
      },
    ],
  },
  {
    title: "Fancy",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/fancy-one-page", text: "One Page" },
          { href: "/fancy-one-page-dark", text: "One Page Dark" },
          { href: "/fancy-multi-page", text: "Multi Page" },
          { href: "/fancy-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Image Parallax",
        links: [
          { href: "/fancy-one-page-image-parallax", text: "One Page" },
          {
            href: "/fancy-one-page-image-parallax-dark",
            text: "One Page Dark",
          },
          { href: "/fancy-multi-page-image-parallax", text: "Multi Page" },
          {
            href: "/fancy-multi-page-image-parallax-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "BG Video",
        links: [
          { href: "/fancy-one-page-bg-video", text: "One Page" },
          { href: "/fancy-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/fancy-multi-page-bg-video", text: "Multi Page" },
          { href: "/fancy-multi-page-bg-video-dark", text: "Multi Page Dark" },
        ],
      },
    ],
  },
  {
    title: "Gradient",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/gradient-one-page", text: "One Page" },
          { href: "/gradient-one-page-dark", text: "One Page Dark" },
          { href: "/gradient-multi-page", text: "Multi Page" },
          { href: "/gradient-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Image Parallax 1",
        links: [
          { href: "/gradient-one-page-image-parallax-1", text: "One Page" },
          {
            href: "/gradient-one-page-image-parallax-1-dark",
            text: "One Page Dark",
          },
          { href: "/gradient-multi-page-image-parallax-1", text: "Multi Page" },
          {
            href: "/gradient-multi-page-image-parallax-1-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "Image Parallax 2",
        links: [
          { href: "/gradient-one-page-image-parallax-2", text: "One Page" },
          {
            href: "/gradient-one-page-image-parallax-2-dark",
            text: "One Page Dark",
          },
          { href: "/gradient-multi-page-image-parallax-2", text: "Multi Page" },
          {
            href: "/gradient-multi-page-image-parallax-2-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "BG Video",
        links: [
          { href: "/gradient-one-page-bg-video", text: "One Page" },
          { href: "/gradient-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/gradient-multi-page-bg-video", text: "Multi Page" },
          {
            href: "/gradient-multi-page-bg-video-dark",
            text: "Multi Page Dark",
          },
        ],
      },
    ],
  },
  {
    title: "Modern",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/modern-one-page", text: "One Page" },
          { href: "/modern-one-page-dark", text: "One Page Dark" },
          { href: "/modern-multi-page", text: "Multi Page" },
          { href: "/modern-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Image Parallax 1",
        links: [
          { href: "/modern-one-page-image-parallax-1", text: "One Page" },
          {
            href: "/modern-one-page-image-parallax-1-dark",
            text: "One Page Dark",
          },
          { href: "/modern-multi-page-image-parallax-1", text: "Multi Page" },
          {
            href: "/modern-multi-page-image-parallax-1-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "Image Parallax 2",
        links: [
          { href: "/modern-one-page-image-parallax-2", text: "One Page" },
          {
            href: "/modern-one-page-image-parallax-2-dark",
            text: "One Page Dark",
          },
          { href: "/modern-multi-page-image-parallax-2", text: "Multi Page" },
          {
            href: "/modern-multi-page-image-parallax-2-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "Typed Text",
        links: [
          { href: "/modern-one-page-typed-text", text: "One Page" },
          { href: "/modern-one-page-typed-text-dark", text: "One Page Dark" },
          { href: "/modern-multi-page-typed-text", text: "Multi Page" },
          {
            href: "/modern-multi-page-typed-text-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "BG Video",
        links: [
          { href: "/modern-one-page-bg-video", text: "One Page" },
          { href: "/modern-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/modern-multi-page-bg-video", text: "Multi Page" },
          { href: "/modern-multi-page-bg-video-dark", text: "Multi Page Dark" },
        ],
      },
    ],
  },
  {
    title: "Slick",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/slick-one-page", text: "One Page" },
          { href: "/slick-one-page-dark", text: "One Page Dark" },
          { href: "/slick-multi-page", text: "Multi Page" },
          { href: "/slick-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Split Screen",
        links: [
          { href: "/slick-one-page-split", text: "One Page" },
          { href: "/slick-one-page-split-dark", text: "One Page Dark" },
          { href: "/slick-multi-page-split", text: "Multi Page" },
          { href: "/slick-multi-page-split-dark", text: "Multi Page Dark" },
        ],
      },
    ],
  },
  {
    title: "Strong",
    links: [
      {
        title: "Main Demo",
        links: [
          { href: "/strong-one-page", text: "One Page" },
          { href: "/strong-one-page-dark", text: "One Page Dark" },
          { href: "/strong-multi-page", text: "Multi Page" },
          { href: "/strong-multi-page-dark", text: "Multi Page Dark" },
        ],
      },
      {
        title: "Image Parallax",
        links: [
          { href: "/strong-one-page-image-parallax", text: "One Page" },
          {
            href: "/strong-one-page-image-parallax-dark",
            text: "One Page Dark",
          },
          { href: "/strong-multi-page-image-parallax", text: "Multi Page" },
          {
            href: "/strong-multi-page-image-parallax-dark",
            text: "Multi Page Dark",
          },
        ],
      },
      {
        title: "BG Video",
        links: [
          { href: "/strong-one-page-bg-video", text: "One Page" },
          { href: "/strong-one-page-bg-video-dark", text: "One Page Dark" },
          { href: "/strong-multi-page-bg-video", text: "Multi Page" },
          { href: "/strong-multi-page-bg-video-dark", text: "Multi Page Dark" },
        ],
      },
    ],
  },
];
